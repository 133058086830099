<template>
  <CContainer fluid class="p-0">
    <base-landing-result>
      <!-- Header -->
      <template #header="{ contact, agent }">
        <div
            class="container-fluid"
            :style="{
              backgroundImage: 'url(' + require('../../assets/images/greeting-banner.jpg') + ')',
              backgroundSize: 'cover'
            }"
        >
          <Header page="greeting" :agent="agent" :contact="contact">
            <template #content>
              <GreetingHeaderForm :agent="agent" :contact="contact" />
            </template>
          </Header>
        </div>
      </template>
      <!-- End of Header -->

      <!-- Property Section -->
      <template #property="{ contact, agent, mapConfig, markerConfig, propertySpecs }">
        <CRow class="hv-landing-map pt-5 px-3">
          <CCol col="12">
            <h1 class="pb-2">Your Property Details</h1>
          </CCol>

          <CCol col="7">
            <div style="height: 500px; position: relative;">
              <Map :map-config="mapConfig" :marker-config="markerConfig" />
              <div id="property">
                <div id="propertyDetail" class="property-detail">
                  <p class="text-white p-2" v-if="contact">
                    {{ contact.street_address_1 }} {{ contact.street_address_2 }},
                    {{ contact.city }}, {{ contact.state }} {{ contact.zip }}
                    <br />
                    <span v-html="propertySpecs" id="propertySpecs" />
                  </p>
                </div>
              </div>
            </div>
          </CCol>

          <CCol col="5" class="pt-0" style="margin-left: auto;">
              <HeaderAgentForm :agent="agent" :contact="contact" />
          </CCol>
        </CRow>
      </template>
      <!-- End of Property Section -->

      <!-- Featured Section -->
      <template #featured="{ listings }">
        <div class="row">
          <div class="col">
            <p class="pb-2 properties-title">My Featured Properties</p>
            <FeaturedBlock :listings="listings" />
          </div>
        </div>
      </template>
      <!-- End of Featured Section -->
    </base-landing-result>
  </CContainer>
</template>

<script>
import BaseLandingResult from "@/components/LandingResult/BaseLandingResult";
import Header from "@/components/Header/GreetingHeader";
import GreetingHeaderForm from "@/components/Header/GreetingHeaderForm";
import Map from "@/components/LandingResult/components/Map";
import HeaderAgentForm from "@/components/LandingResult/components/HeaderAgentForm";
import FeaturedBlock from "@/components/LandingResult/components/FeaturedBlock";

export default {
  name: "LandingResultView",
  components: {
    BaseLandingResult, Header, GreetingHeaderForm, Map, HeaderAgentForm, FeaturedBlock
  },
};
</script>

<style type="scss" lang="scss">
body {
  background-color: #ffffff !important;
}

.header-text {
  font-size: 16px;
  font-weight: 300;
}

.greeting-container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 64px;

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1400px) {
    max-width: 1440px;
  }
}

.hv-landing-map {
  margin-bottom: 3rem;
}

.hv-landing-properties {
  .properties-title {
    font-size: 20px;
    font-weight: 700;
  }
}
</style>
