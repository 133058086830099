var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CContainer',{staticClass:"p-0",attrs:{"fluid":""}},[_c('base-landing-result',{scopedSlots:_vm._u([{key:"header",fn:function(ref){
var contact = ref.contact;
var agent = ref.agent;
return [_c('div',{staticClass:"container-fluid",style:({
            backgroundImage: 'url(' + require('../../assets/images/greeting-banner.jpg') + ')',
            backgroundSize: 'cover'
          })},[_c('Header',{attrs:{"page":"greeting","agent":agent,"contact":contact},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GreetingHeaderForm',{attrs:{"agent":agent,"contact":contact}})]},proxy:true}],null,true)})],1)]}},{key:"property",fn:function(ref){
          var contact = ref.contact;
          var agent = ref.agent;
          var mapConfig = ref.mapConfig;
          var markerConfig = ref.markerConfig;
          var propertySpecs = ref.propertySpecs;
return [_c('CRow',{staticClass:"hv-landing-map pt-5 px-3"},[_c('CCol',{attrs:{"col":"12"}},[_c('h1',{staticClass:"pb-2"},[_vm._v("Your Property Details")])]),_c('CCol',{attrs:{"col":"7"}},[_c('div',{staticStyle:{"height":"500px","position":"relative"}},[_c('Map',{attrs:{"map-config":mapConfig,"marker-config":markerConfig}}),_c('div',{attrs:{"id":"property"}},[_c('div',{staticClass:"property-detail",attrs:{"id":"propertyDetail"}},[(contact)?_c('p',{staticClass:"text-white p-2"},[_vm._v(" "+_vm._s(contact.street_address_1)+" "+_vm._s(contact.street_address_2)+", "+_vm._s(contact.city)+", "+_vm._s(contact.state)+" "+_vm._s(contact.zip)+" "),_c('br'),_c('span',{attrs:{"id":"propertySpecs"},domProps:{"innerHTML":_vm._s(propertySpecs)}})]):_vm._e()])])],1)]),_c('CCol',{staticClass:"pt-0",staticStyle:{"margin-left":"auto"},attrs:{"col":"5"}},[_c('HeaderAgentForm',{attrs:{"agent":agent,"contact":contact}})],1)],1)]}},{key:"featured",fn:function(ref){
          var listings = ref.listings;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"pb-2 properties-title"},[_vm._v("My Featured Properties")]),_c('FeaturedBlock',{attrs:{"listings":listings}})],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }