<template>
  <div v-if="page != 'adgentWidget'" class="row">
    <div class="col col-lg-6 col-md-12 col-xl-5 col-xxl-5 hv-hs-container">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import HeaderAgentForm from "@/components/LandingResult/components/HeaderAgentForm";

export default {
  name: "GreetingHeader",
  components: {},

  props: {
    page: { type: String, default: "" },
    agent: Object,
    contact: Object
  },
  created() {}
};
</script>

<style scoped lang="scss">
.header-description {
  line-height: 250%;
}

.hv-hs-title {
  font-size: 4.5rem;
  font-weight: 600;
  letter-spacing: 4px;
  position: relative;
  top: -1rem;
}

.hv-header-content {
  padding-bottom: 50px;
  padding-top: 50px;
}

.hv-header-content {
  max-width: 1140px;
}

.hv-hs-container {
  margin-left: 105px;
  padding: 38px 0 13px 0;
}

</style>
