<template>
  <CCard class="greeting-panel">
    <CCardBody class="hv-greeting-card">
      <div class="text-dark hv-agent-details">
        <CRow>
          <CCol style="margin-bottom: 10px;">
            <h2 class="hv-ad-title">What’s your home worth?</h2>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <p >
              Hi, I am/We are (Agent), and I/we would like to offer you a
              complimentary home valuation and quick peek at the market activity
              near your home. Register below to get your free valuation.
            </p>
          </CCol>
        </CRow>
        <CRow
          ><CCol>
          <CInput
              v-model="email"
              style="height: 38px"
              placeholder="Email"
              :class="{ 'is-invalid': isEmailError }"
              :isValid="isEmailError ? false : null"
          >

            <template #invalid-feedback>
                    <span class="invalid-feedback"
                          v-if="isEmailError">Please provide valid email
                    </span>
            </template>
          </CInput>

        </CCol
        ></CRow>

        <CRow
          ><CCol>
            <CButton style="height: 38px; line-height: 1" block size="lg" :disabled="!contact || loading" color="primary" @click="handleGetEstimates">
              <CSpinner v-if="loading" size="sm"/>
              Get Estimated Home Value </CButton>
          </CCol></CRow
        >
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { cibFacebookF, cibTwitter, cibLinkedinIn } from "@coreui/icons";
import validateEmail from '../../utils/EmailValidator';

export default {
  name: "GreetingHeaderForm",
  components: {},
  facebookIcon: cibFacebookF,
  twitterIcon: cibTwitter,
  cibLinkedinInIcon: cibLinkedinIn,
  data(){
    return {
      isEmailError: false,
      email: '',
    }
  },
  props: {
    agent: Object,
    contact: Object,
    loading: Boolean,
  },

  created() {},
  methods: {
    handleGetEstimates(){
      if (!this.email || !validateEmail(this.email)){
        this.isEmailError = true;
        return;
      }
      this.$emit('on-get-started', this.email);
      this.isEmailError = false;
    },
  },
  watch: {
    email(){
      this.isEmailError = false;
    }
  }
};
</script>

<style scoped lang="scss">
.agent-photo {
  height: auto;
  width: 49%;
}

.agent-photo-one {
  max-height: 200px;
}

.greeting-panel {
  background: rgba(247, 247, 247, 1);
}


.hv-greeting-card {
  padding: 50px;

  .hv-ad-title {
    font-size: 32px;
  }

  p {
    font-size: 16px;
  }

 
}

.hv-header-card {
  padding: 20px 15px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
  // background: #fff;
  // background-color: hsla(0, 0%, 100%, 0.85);

  .hv-agent-details {
    .hv-ad-title {
      font-size: 1.125rem;
      margin-bottom: 0.5rem;
    }

    .hv-agent-photo,
    .hv-agent-info {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 15px;
      padding-left: 15px;
    }

    .hv-agent-info {
      .agent-name {
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 0;
      }

      .agent-company {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 13px;
      }

      .hv-header-social {
        .btn {
          margin-right: 1rem;
          padding: 0;
        }
      }

      .btn {
        padding: 0.5rem 0;
      }
    }
  }
}
</style>
